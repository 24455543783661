import React from "react";
import { useTranslation } from "react-i18next";
import { css, styled } from "goober";

import { WeaponImageBg } from "@/game-eft/components/WeaponImage.jsx";
import { DEFAULT_CURRENCY_FORMAT_OPTIONS } from "@/game-eft/constants/flea-market.mjs";
import GameGuideTileLg from "@/marketing/GameGuideTileLg.jsx";

const Row = styled("a")`
  display: flex;
  padding: 0 var(--sp-4);
  height: 5.875rem;
  gap: var(--sp-3);
  align-items: center;
  background: var(--shade8);

  &:hover {
    background: var(--shade3-15);
  }

  &:not(:last-child) {
    box-shadow: inset 0 -1px var(--card-inset-border);
  }

  .weapon-name {
    color: #e3e5ea;
    font-size: var(--sp-3_5e);
    font-weight: 725;
  }

  .role {
    font-size: 0.813rem;
    color: #7f838b;
  }

  .price {
    font-size: 0.813rem;
    color: var(--orange);
  }
`;

const rows = [
  {
    weapon: {
      id: "5bd70322209c4d00d7167b8f",
      longName: "MP7A2",
    },
    slug: "as-val-9x39-special-assault-rifle",
    trader: [
      "gen-eft:trader.5a7c2eca46aef81a7ca2145d.nick",
      "Mechanic",
    ] as const,
    price: 25329,
  },
  {
    weapon: {
      id: "57838ad32459774a17445cd2",
      longName: 'VSS "Vintorez"',
    },
    slug: "vss-vintorez-9x39-special-sniper-rifle",
    trader: ["gen-eft:trader.5c0647fdd443bc2504c2d371.nick", "Jaeger"] as const,
    price: 39122,
  },
  {
    weapon: {
      id: "5ac66cb05acfc40198510a10",
      longName: "AK-101",
    },
    slug: "kalashnikov-ak-101-5-56x45-assault-rifle",
    trader: [
      "gen-eft:trader.5a7c2eca46aef81a7ca2145d.nick",
      "Mechanic",
    ] as const,
    price: 19971,
  },
];
export default function HomeStatisticsTile() {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  return (
    <div className={TileContainer()}>
      <GameGuideTileLg
        title={["home:guides.eft.marketplace.title", "Marketplace"]}
        description={[
          "home:guides.eft.marketplace.description",
          "Navigate the Tarkov market on Blitz. Explore traders’ offerings, from weapons and ammo to gear, meds, provisions, and more.",
        ]}
        buttonText={["common:navigation.viewMarketplace", "View Marketplace"]}
        href={"/eft/flea-market"}
      >
        {rows.map((row) => (
          <Row key={row.slug} href={`/eft/flea-market/details/${row.slug}`}>
            <WeaponImageBg
              // @ts-ignore
              weapon={row.weapon}
            />
            <div className="flex column gap-sp-1">
              <span className="weapon-name">{row.weapon.longName}</span>
              <div className="flex row gap-sp-3">
                <span className="role">{t(...row.trader)}</span>
                <span className="price">
                  {row.price.toLocaleString(
                    language,
                    DEFAULT_CURRENCY_FORMAT_OPTIONS,
                  )}
                </span>
              </div>
            </div>
          </Row>
        ))}
      </GameGuideTileLg>
    </div>
  );
}

const TileContainer = () => css`
  .card-contents {
    min-height: auto;
  }
`;
